import { TextInput } from "carbon-components-react";
import { useEffect, useState } from "react";
import "./App.css";

const App = () => {
  const [result, setResult] = useState("X");
  const [values, setValues] = useState({ a: "", b: "", c: "" });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (
      parseFloat(values.a) >= 0 &&
      parseFloat(values.a) <= 100 &&
      parseFloat(values.b) >= 0 &&
      parseFloat(values.b) <= 100 &&
      parseFloat(values.c) >= 0 &&
      parseFloat(values.c) <= 100
    ) {
      const conta = (values.c * values.a) / values.b;

      setResult(conta.toFixed(3));
    }
  }, [values]);

  const showInCalculator = (a, b, ba) => {
    setValues({ a, b, c: ba });
    document.getElementById("a").focus();
  };

  return (
    <div>
      <div className="content-container">
        <div className="title">Bayesian Calculator</div>
        <div className="desc">
          Bayes' Theorem describes the probability of an event, based on prior
          knowledge of conditions that might be related to the event. <br />{" "}
          <a
            href="https://en.wikipedia.org/wiki/Bayes%27_theorem"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
        <TextInput
          labelText="Probability of Outcome A"
          placeholder="Percent of A"
          value={values.a}
          id="a"
          onChange={handleChange}
        />
        <TextInput
          labelText="Probability of Outcome B"
          placeholder="Percent of B"
          value={values.b}
          id="b"
          onChange={handleChange}
        />
        <TextInput
          labelText="Probability the B is true, if A is true"
          placeholder="Percent of B given A is true"
          value={values.c}
          id="c"
          onChange={handleChange}
        />
        <div className="img-container">
          <img src="/formula.png" alt="Formula" />
        </div>
        <div className="result">
          The probability of A, if B is true, is {result}%
        </div>
      </div>
      <div className="description-container">
        <p>Bayes' rule is simple. It looks like this:</p>
        <div className="formula-container">
          <img src="/formula.png" alt="Formula" />
        </div>
        <p>
          If your eyes glazed over in your last statistics class, we can say
          this as a sentence:
        </p>
        <p>
          <i>
            “The probability of A, if B is true…is equal to the probability of
            B, if A is true, multiplied by the probability of A, divided by the
            probability of B.”<sup>1</sup>
          </i>
        </p>
        <p>
          The math is equally simple - multiplication and division only. And
          even though we mastered these ideas before exiting elementary school,
          somehow, the implication of this theorem still leaves society
          dumbfounded.
        </p>
        <div className="mt-separator"></div>
        <p>
          <b>EXAMPLE #1</b>
        </p>
        <p>
          Let’s play a game. All statistics are mostly, but not entirely
          fictitious.
        </p>
        <p>
          <b>P(A)</b> = Odds that someone in California is employed full-time
          and works in tech. <br />
          <b>P(B)</b> = Odds that someone in California has a net worth in
          excess of $10M. <br />
          <b>P(B|A)</b> = Odds of having a net worth in excess of $10M, given
          that they’re at a startup
        </p>
        <p>
          <b>P(A)</b> = 0.13<sup>2</sup>
          <br />
          <b>P(B)</b> = 0.015<sup>3</sup>
          <br />
          <b>P(B|A)</b> = 0.05<sup>4</sup>
        </p>
        <p>
          So now, using bayes theorem we can calculate P(A|B), which in English
          is “the odds that someone works at a tech startup, given that they’re
          worth $10M+.”
        </p>
        <p>This math is easy:</p>
        <div className="formula-container">
          <img src="/formula2.png" alt="Formula" />
          <p className="show-calculator">
            <button onClick={() => showInCalculator(13, 0.15, 0.5)}>
              Show in calculator
            </button>
          </p>
        </div>
        <p>
          Bayes says (or I say using his theorem?) that 43.3% of the $10M+ net
          worth crowd in California work at startups. Now this is where it gets
          interesting. Most millionaires are over 65<sup>5</sup>. This is even
          more pronounced for billionaires<sup>6</sup>. So what if we were to
          assume that 40% of all the $10M+ folks are retired. That would mean
          only 60% are actually of working age.
        </p>
        <p>43.3%/60% = ~72%.</p>
        <p>
          So, when you meet a <i>really</i> rich person in California who isn’t
          over 65, there’s an extremely good chance that they’re in tech.
        </p>
        <p>
          Does that mean that{" "}
          <i>
            if you work in tech, you’re extremely likely to build $10M+ in net
            worth?
          </i>
        </p>
        <p>
          Of course not. We already have that number, 5%, which is charitable.
        </p>
        <p>
          Bayes theorem distinguishes between what we see (most of the working
          age $10M+ crowd in Cali are in tech) from what is true (most tech
          people, even in Cali aren’t going to amass that level of wealth).
          Bayes is a cruel master.
        </p>
        <div className="mt-separator"></div>
        <p>
          <b>EXAMPLE #2</b>
        </p>
        <p>
          Again, all statistics are mostly, but not <i>entirely</i> fictitious.
          Let's talk about old people.
        </p>
        <p>
          <div className="formula-container">
            <img src="/formula3.png" alt="Formula" />
          </div>
        </p>
        <p>
          <b>P(A)</b> = Odds that a human being was born in Japan <br />
          <b>P(B)</b> = Odds that a human being is alive, and over 112 years
          old.
          <br />
          <b>P(B|A)</b> = Odds that a human being is alive and over 112, given
          that they were born in Japan
        </p>
        <p>
          <b>P(A)</b> = 0.016<sup>7</sup>
          <br />
          <b>P(B)</b> = 5.75*10
          <sup>
            -9<div>8</div>
          </sup>
          <br />
          <b>P(B|A)</b> = 1.28*10
          <sup>
            -7<div>9</div>
          </sup>
        </p>
        <p>
          Again, using bayes theorem we can calculate P(A|B), which in English
          is “the odds that someone was born in Japan, given that they are alive
          and over the age of 112.”
        </p>
        <p>This math is easy:</p>
        <p>
          <div className="formula-container">
            <img src="/formula4.png" alt="Formula" />
            <p className="show-calculator">
              <button
                onClick={() =>
                  showInCalculator(0.016, 0.00000000575, 0.000000128)
                }
              >
                Show in calculator
              </button>
            </p>
          </div>
        </p>
        <p>
          This means that if you meet someone over 112, there should be better
          than a 1 in 3 chance that they reside in Japan. The empirical data
          suggest this is true!<sup>10</sup> Does this mean that someone born in
          Japan is remotely likely to live to be 112? Heck no. The odds suggest
          that only a handful per billion births yield a supercentenarian of
          this ilk.
        </p>
        <p>
          Or, to pervert the language of the world’s most interesting man. “Most
          people don’t live to be 112, but when they do, they’re often from
          Japan.”
        </p>
        <div className="mt-separator"></div>
        <p>
          <b>EXAMPLE #3</b>
        </p>
        <p>
          Once more, with feeling. All statistics are mostly, but not{" "}
          <i>entirely</i> fictitious.
        </p>
        <p>
          Now let’s try the type of example that will inevitably engender
          vitriol, controversy, and general gnashing of teeth:
        </p>
        <p>
          <div className="formula-container">
            <img src="/formula3.png" alt="Formula" />
          </div>
        </p>
        <p>
          <b>P(A)</b> = Odds that an American is unvaccinated <br />
          <b>P(B)</b> = Odds that an American died of COVID-19 between 4/4/2021
          and 12/25/2021 <br />
          <b>P(B|A)</b> = Odds that an American has died of COVID-19, given that
          they are unvaccinated
        </p>
        <p>
          <b>P(A)</b> = 0.23<sup>11</sup>
          <br />
          <b>P(B)</b> = 0.00036<sup>12</sup>
          <br />
          <b>P(B|A)</b> = 0.00126<sup>13</sup>
        </p>
        <p>
          In english, P(A|B) means “the probability that an American is
          unvaccinated, given that they died of COVID-19 between April 4th and
          Christmas of 2021.”
        </p>
        <p>The math is easy:</p>
        <p>
          <div className="formula-container">
            <img src="/formula6.png" alt="Formula" />
            <p className="show-calculator">
              <button onClick={() => showInCalculator(0.23, 0.00036, 0.00126)}>
                Show in calculator
              </button>
            </p>
          </div>
          <p className="show-calculator">
            {/* <button onClick={() => showInCalculator(a, b, ba)}>Show in calculator</button> */}
          </p>
        </p>
        <p>The result above, and the individual terms, imply two points:</p>
        <p>
          <ol>
            <li>
              1. COVID-19 is not <i>that</i> dangerous, even to the
              unvaccinated.
            </li>
            <li>
              2. The vast majority (80%+) of COVID-19 deaths after vaccinations
              became available <i>were</i> to unvaccinated individuals.
              Therefore, <i>if</i> someone passed of COVID in that latter part
              of 2021, a lack of vaccination was a likely driver, and thus,
              vaccines saved many lives.
            </li>
          </ol>
        </p>
        <div className="mt-separator"></div>
        <p>
          These types of thought experiments are illustrative, informative, and
          good exercises for one's reasoning (and they're fun!). For the
          intrepid reader, consider the following thought experiments:
        </p>
        <p>
          What is the probability of a human being between the ages of 20 and 40
          receiving paychecks from the NBA, given that they are at least 7 feet
          tall? How does the probability of an NBA player being of caucasian
          ancestry differ between the subset of players above and below 7 feet
          in height?
        </p>
        <p>
          What is the probability of a human being committing suicide (dark, I
          realize)? How does that probability vary, given that the individual
          has recently won the lottery?<sup>14</sup>
        </p>
        <p>
          What is the probability of becoming president, given that you have
          attended Harvard, Yale, or Princeton as an undergraduate or a law
          student? (Since, notably,{" "}
          <a
            href="https://en.wikipedia.org/wiki/List_of_presidents_of_the_United_States_by_education"
            target="blank"
            rel="noreferrer"
          >
            15 presidents did!
          </a>
          ).
        </p>
        <p>Written by Evan Coopersmith</p>
        <div className="separator"></div>
        <p>
          <ul>
            <li>
              <sup>1</sup>This skirts some more esoteric discussions of
              conditions and independence. You’re welcome.
            </li>
            <li>
              <sup>2</sup>California has{" "}
              <a
                href="https://business.ca.gov/industries/high-tech/#:~:text=California%20is%20the%20global%20leader,any%20other%20state%20since%202010."
                target="_blank"
                rel="noreferrer"
              >
                ~1.9M tech employees
              </a>
              , in a state with a population of{" "}
              <a
                href="https://en.wikipedia.org/wiki/California"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                ~40M
              </a>
              . Let’s say ~20M are of working age and ~15M are employed
              full-time. 1.9M/15M ~ 0.13.
            </li>
            <li>
              <sup>3</sup>A household net worth of{" "}
              <a
                href="https://dqydj.com/top-one-percent-united-states/"
                target="blank"
                rel="noreferrer"
              >
                ~$11M
              </a>{" "}
              places a family in the top 1%. But folks in California are a
              little wealthier on average than the U.S. in general, so we’ll
              make this 1.5%.
            </li>
            <li>
              <sup>4</sup>
              <a
                href="https://www.investopedia.com/articles/personal-finance/040915/how-many-startups-fail-and-why.asp#:~:text=In%202019%2C%20the%20failure%20rate,70%25%20in%20their%2010th%20year."
                target="_blank"
                rel="noreferrer"
              >
                10% of startups exit
              </a>
              , most do not produce $10M wealth, but then, one can probably work
              at a handful of startups during one’s career, so maybe a 5% chance
              of catching lightning in a bottle or working for a FAANG company
              for a couple decades and investing well during a ~30yr period
              seems defensible (if a little optimistic)?
            </li>
            <li>
              <sup>5</sup>One article suggests that{" "}
              <a
                href="https://spendmenot.com/blog/what-percentage-of-americans-are-millionaires/#:~:text=According%20to%20a%20report%20about,Only%201%25%20are%20below%2035."
                target="_blank"
                rel="noreferrer"
              >
                38% of US millionaires are over 65.
              </a>
            </li>
            <li>
              <sup>6</sup>The proportion of billionaires over 65 is even higher
              (
              <a
                href="https://www.statista.com/statistics/621046/age-distribution-of-billionaires-globally/"
                target="_blank"
                rel="noreferrer"
              >
                at least 40-45%
              </a>
              ).
            </li>
            <li>
              <sup>7</sup>The population of Japan is{" "}
              <a
                href="https://en.wikipedia.org/wiki/Japan"
                target="_blank"
                rel="noreferrer"
              >
                ~125M.
              </a>{" "}
              There are{" "}
              <a
                href="https://en.wikipedia.org/wiki/World_population"
                target="_blank"
                rel="noreferrer"
              >
                ~8B
              </a>{" "}
              people in the world. Simple enough. (And yes, I realize those
              proportions might be different historically when many of those 8B
              people were born, and yes, people can immigrate or emigrate, blah
              blah blah…)
            </li>
            <li>
              <sup>8</sup>There are 46 people currently living,{" "}
              <a
                href="https://en.wikipedia.org/wiki/List_of_the_oldest_living_people"
                target="_blank"
                rel="noreferrer"
              >
                over 112
              </a>
              . Again, simple division, see point above.
            </li>
            <li>
              <sup>9</sup>Using the same wikipedia page linked above, there are
              16 people residing in Japan currently over the age of 112 (among a
              population of 125M).
            </li>
            <li>
              <sup>10</sup>Using the same wikipedia page, 16 of the 46 living
              persons over 112 reside in Japan. That’s ~35%.
            </li>
            <li>
              <sup>11</sup>US vaccination rate is currently around 77%,
              according to the{" "}
              <a
                href="https://www.mayoclinic.org/coronavirus-covid-19/vaccine-tracker"
                target="_blank"
                rel="noreferrer"
              >
                Mayo Clinic.
              </a>
            </li>
            <li>
              <sup>12</sup>According to the{" "}
              <a href="https://www.cdc.gov/mmwr/volumes/71/wr/mm7104e2.htm">
                CDC
              </a>
              , ~117K have perished from COVID-19 in a country of{" "}
              <a
                href="https://en.wikipedia.org/wiki/Demographics_of_the_United_States"
                target="_blank"
                rel="noreferrer"
              >
                ~330M
              </a>
              .
            </li>
            <li>
              <sup>13</sup>Just under 95K in a population of ~330M, 23% of which
              (~75M), according to the same CDC site linked above.
            </li>
            <li>
              <sup>14</sup>
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/29135442/"
                target="_blank"
                rel="noreferrer"
              >
                https://pubmed.ncbi.nlm.nih.gov/29135442/
              </a>
            </li>
          </ul>
        </p>

        <div className="show-calculator blog">
          <a
            href="https://ae.studio/blog/old-bayesian-seasoning?utm_source=sds&utm_medium=referral&utm_campaign=bayesianmethod&utm_content=about&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
            target="_blank"
            rel="noreferrer"
          >
            See this post on our blog
          </a>
        </div>
      </div>
      <img
        src="https://ae.studio/img/same-day-skunkworks/watermark.png"
        alt="Same day skunkworks"
        className="watermark rotating"
        onClick={() => window.open("https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=bayesianmethod&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252")}
      />
      <div className="love">
        Made with ♥ by{" "}
        <a href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=bayesianmethod&utm_content=footer&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252" target="_blank" rel="noopener noreferrer">
          Agency Enterprise
        </a>
      </div>
    </div>
  );
};

export default App;
